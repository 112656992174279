/* Define the keyframes for the fade-in effect */
@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

/* Apply the animation to the desired element */
.fade-in {
    animation: fadeIn 1s ease-out forwards;
}
  


@keyframes expandHeight {
    from {
      max-height: 0;
      overflow: hidden;
    }
    to {
      max-height: 1500px; /* Arbitrary large max-height to ensure full expansion */
    }
  }

  
  /* Apply the animation to the desired element */
  .expand-down {
    animation: expandHeight 3s ease-out forwards;
  }
  
  @keyframes expandHeightLarge {
      from {
        max-height: 0;
        overflow: hidden;
      }
      to {
        max-height: 3000px; /* Arbitrary large max-height to ensure full expansion */
      }
  }

.expand-down-large {
    animation: expandHeightLarge 2s ease-out forwards;
  }
  